.billing-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.billing-container p {
  text-align: start;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
}

.main-txt {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.billing-tabs .ant-tabs-nav {
  margin-bottom: 20px;
}

.aws-tab-content,
.open-tab-content,
.stability-diffusion-tab-content,
.gemini-ai-tab-content,
.huggingface-tab-content {
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.input-section {
  display: flex;
  align-items: center;
}

.chart {
  margin-top: 20px;
}

.info-section {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
